import { useState } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import PageTitle from '../../../Layout/AppMain/PageTitle'
import Cards from './Cards'
import SearchBox from '../../../Layout/AppHeader/Components/SearchBox'
import { Row } from 'reactstrap'
import { useResource } from 'rest-hooks'
import ServiceResource from '../../../resources/service'
import { useParams } from 'react-router-dom'
import { CustomerContext } from '../'

export default function Service() {
  let { brandId } = useParams()

  let { results: services } = useResource(ServiceResource.list(), {
    customerid: brandId,
  })

  const [searchValue, setSearchValue] = useState('')
  if (searchValue) {
    services = services.filter(c =>
      c.name
        .toLowerCase()
        .match(
          new RegExp(
            `.*${searchValue.toLowerCase().split('').join('.*')}.*`,
            'i',
          ),
        ),
    )
  }

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CustomerContext.Consumer>
          {({ customer }) => (
            <PageTitle
              heading="Services"
              subheading={`${customer.name} ${
                customer.description?.trim() ? ` - ${customer.description}` : ''
              }`}
              icon="pe-7s-tools icon-gradient bg-ripe-malin"
            />
          )}
        </CustomerContext.Consumer>
        <Row className="ml-2">
          <SearchBox value={searchValue} setValue={setSearchValue} />
        </Row>
        <Cards data={services} />
      </CSSTransitionGroup>
    </>
  )
}
