import { AuthStore } from 'sod.it-ops.auth'
import { Settings } from '../Config/Settings'

class RootStore {
  public authStore: AuthStore | undefined
  public settings: Settings | undefined

  constructor(settings: Settings) {
    this.settings = settings
  }

  setAuthstore(authStore: AuthStore) {
    this.authStore = authStore
  }
}

export default RootStore
