import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Link } from 'react-router-dom'
import { Row, Col, Card, CardBody, CardHeader, Container } from 'reactstrap'

export default function CardsAdvanced({ data }) {
  const items =
    data &&
    data.map(lane => (
      <Col key={lane.id} md="4">
        <Link
          to={`lanes/${lane.id}`}
          style={{ textDecoration: 'none', color: 'initial' }}
        >
          <Card className="main-card mb-3 card-hover-shadow-2x card-border">
            <CardHeader>
              <p className="mt-3">{lane.serviceNumber}</p>
            </CardHeader>
            <CardBody>
              <h6>{lane.name}</h6>
              <p className="text-muted mb-1">{lane.description}</p>
              <p className="mb-0 text-muted">{lane.routingPlans?.length} routing plans</p>
            </CardBody>
          </Card>
        </Link>
      </Col>
    ))
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <Row className="mt-3">{items}</Row>
        </Container>
      </CSSTransitionGroup>
    </>
  )
}
