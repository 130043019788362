import AuthedResource from './authedResource'
import KpiGroupResource from './kpiGroup'
import EndpointResource from './algorithmEndpoint'

export default class AlgorithmResource extends AuthedResource {
  static urlRoot = `${AuthedResource.baseUrl}/algorithms`

  pk() {
    return this.id
  }

  readonly id: string = ''
  readonly name = ''
  readonly customerId: string = ''
  readonly active: boolean = false
  readonly description = ''
  readonly endpoints: EndpointResource[] = []
  readonly kpiGroups: KpiGroupResource[] = []

  static schema = {
    kpiGroups: [KpiGroupResource],
    endpoints: [EndpointResource],
  }
}
