import { AbstractInstanceType } from 'rest-hooks/rest'
import AuthedResource from './authedResource'

export default class KpiResource extends AuthedResource {
  pk() {
    return this.id
  }

  readonly id: string = ''
  readonly name = ''
  readonly displayName = ''
  readonly description = ''
  readonly type: 'positive' | 'negative' = 'positive'
  readonly active: boolean = true
  readonly minValue = 0
  readonly maxValue = 0

  static get key() {
    return 'KpiResource'
  }

  /**
   * Get the url for a Resource
   */
  static url<T extends typeof AuthedResource>(
    this: T,
    urlParams: { algorithmId: string; kpiGroupId: string } & Partial<
      AbstractInstanceType<T>
    >,
  ): string {
    if (urlParams && urlParams.algorithmId && urlParams.kpiGroupId) {
      if (this.pk(urlParams) !== undefined) {
        const { algorithmId, kpiGroupId } = urlParams
        return `${
          AuthedResource.baseUrl
        }/algorithms/${algorithmId}/kpigroups/${kpiGroupId}/kpis/${this.pk(
          urlParams,
        )}`
      }
    }
    // since we're overriding the url() function we must keep the type the
    // same, which means we might not get urlParams
    throw new Error('Kpi require algorithmId and kpiGroupId to retrieve')
  }

  /**
   * Get the url for many Resources
   */
  static listUrl(searchParams: {
    algorithmId: string
    kpiGroupId: string
  }): string {
    if (searchParams && searchParams.algorithmId && searchParams.kpiGroupId) {
      const { algorithmId, kpiGroupId, ...realSearchParams } = searchParams
      const params = new URLSearchParams(realSearchParams as any)
      // this is essential for consistent url strings
      params.sort()
      return `${
        AuthedResource.baseUrl
      }/algorithms/${algorithmId}/kpigroups/${kpiGroupId}/kpis/?${params.toString()}`
    }
    throw new Error('Kpi require algorithmId and kpiGroupId to retrieve')
  }
}
