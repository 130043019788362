import { useResource, useFetcher } from 'rest-hooks'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import PageTitle from '../../../Layout/AppMain/PageTitle'
import AlgorithmResource from '../../../resources/algorithm'
import AlgorithmsList from './AlgorithmsList'
import { useParams, Route } from 'react-router-dom'
import Algorithm from './Algorithm'
import { CustomerContext } from '../'

export default function Algorithms({ match }) {
  let { brandId } = useParams()

  const { results: algorithms } = useResource(AlgorithmResource.list(), {
    customerid: brandId,
  })

  const deleteResource = useFetcher(AlgorithmResource.delete())
  const getResource = useFetcher(AlgorithmResource.list(), {
    customerid: brandId,
  })

  const deleteAlgorithm = async id => {
    await deleteResource({ id })
    await getResource({ customerid: brandId })
  }

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <>
          <CustomerContext.Consumer>
            {({ customer }) => (
              <PageTitle
                heading="Algorithms"
                subheading={`${customer.name} ${
                  customer.name ? ` - ${customer.description}` : ''
                }`}
                icon="pe-7s-graph1 icon-gradient bg-ripe-malin"
              />
            )}
          </CustomerContext.Consumer>
          <AlgorithmsList data={algorithms} onDelete={deleteAlgorithm} />
        </>
        <Route
          path={`${match.path}/:algorithmId`}
          render={routeProps => (
            <Algorithm parentUrl={match.url} {...routeProps} />
          )}
        />
      </CSSTransitionGroup>
    </>
  )
}
