import AuthedResource from './authedResource'

export default class ServiceResource extends AuthedResource {
  static urlRoot = AuthedResource.baseUrl + '/services'
  pk() {
    return this.id
  }

  readonly id: string = ''
  readonly name: string = ''
  readonly customerId: string = ''
  readonly hidden: boolean = false
  readonly active: boolean = true
  readonly lanes: object[] = []
}
