import { useState } from 'react'
import { useResource, useFetcher } from 'rest-hooks'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import AlgorithmResource from '../../../resources/algorithm'
import KpiGroupResource from '../../../resources/kpiGroup'
import KpiResource from '../../../resources/kpi'
import SiteResource, { getEndpointFromSite } from '../../../resources/site'

import { useParams, useHistory } from 'react-router-dom'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Label,
  Collapse,
} from 'reactstrap'
import { AvGroup, AvInput, AvForm } from 'availity-reactstrap-validation'
import KpiGroup from './KpiGroup'
import Endpoint from './Endpoint'
import Accordion from './Accordion'
import AccordionItem from './AccordionItem'
import SimpleModal from '../Shared/Components/SimpleModal'
import cx from 'classnames'
import { toast, Slide } from 'react-toastify'

const isNew = s => (typeof s === 'string' ? s : s?.id)?.startsWith('new')
const serializeAlgoResource = (data: AlgorithmResource, create = false) => ({
  name: data.name,
  description: data.description,
  active: data.active,
  customerId: data.customerId,
})

export default function Algorithm({ parentUrl }) {
  let { brandId, algorithmId } =
    useParams<{ brandId: string; algorithmId: string }>()

  let algo = !isNew(algorithmId)
    ? useResource(AlgorithmResource.detail(), {
        customerid: brandId,
        id: algorithmId,
      })
    : AlgorithmResource.fromJS({ id: algorithmId, customerId: brandId })

  const getResource = useFetcher(AlgorithmResource.detail())
  const getListResource = useFetcher(AlgorithmResource.list())
  const updateResource = useFetcher(AlgorithmResource.partialUpdate())
  const createResource = useFetcher(AlgorithmResource.create())

  const [data, setData] = useState(algo)

  const history = useHistory()
  const backConfirmed = () => history.push(parentUrl)

  let [changedElem] = useState(new Set())
  let [changed, setChanged] = useState(false)

  const onChange = (change, id) => {
    changedElem[change ? 'add' : 'delete'](id)
    setChanged(changedElem.size !== 0)
  }

  let [nestedModal, setNestedModal] = useState(false)
  const back = () => (changed ? setNestedModal(true) : backConfirmed())

  const setFormData = e => {
    const [name, _] = (e.currentTarget.name ?? e.currentTarget.id).split('_')
    const val = e.currentTarget.value

    onChange(true, data.id)
    switch (name) {
      case 'active':
        setData({ ...data, active: !data?.active } as AlgorithmResource)
        break
      default:
        setData({ ...data, [name]: val } as AlgorithmResource)
        break
    }
  }

  const save = async () => {
    try {
      const res = isNew(algo.id)
        ? await createResource({}, serializeAlgoResource(data, true))
        : await updateResource({ id: data.id }, serializeAlgoResource(data))
      toast.success('Success!', {
        transition: Slide,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success',
      })
      onChange(false, data.id)
      getListResource({ customerid: brandId })
      setData({ ...algo, ...res } as AlgorithmResource)
      isNew(algo.id) &&
        window.history.replaceState(null, '', `${parentUrl}/${res.id}`)
    } catch (res) {
      res.response.text().then(text => {
        toast.error(text, {
          transition: Slide,
          closeButton: true,
          autoClose: 5000,
          position: 'bottom-center',
        })
      })
    }
  }

  const onUpdate = async () => {
    setData(await getResource({ customerid: brandId, id: data.id }))
  }

  // ====================
  // Kpi-related
  // ====================
  const isCreatingKpi =
    data?.kpiGroups && isNew(data?.kpiGroups[data.kpiGroups.length - 1])

  const onDeleteKpi = async group => {
    if (isNew(group)) {
      setData({
        ...data,
        kpiGroups: data?.kpiGroups.filter(g => g.id !== group.id),
      } as AlgorithmResource)
    } else {
      await onUpdate()
    }
  }
  const createLocalKpiGroup = () => {
    setData(
      AlgorithmResource.fromJS({
        ...data,
        kpiGroups: [
          ...data!.kpiGroups,
          KpiGroupResource.fromJS({
            id: `new_kpigroup_${data?.kpiGroups.length}`,
            kpIs: [
              KpiResource.fromJS({
                id: `new_kpi_${data?.kpiGroups.length}`,
              }),
            ],
          }),
        ],
      }),
    )
  }

  // ====================
  // Endpoint-related
  // ====================
  const { results: sites } = useResource(SiteResource.list(), {
    customerid: brandId,
  })
  const isCreatingEndpoint =
    data?.endpoints && isNew(data?.endpoints[data.endpoints.length - 1])
  const onDeleteEndpoint = async endpoint => {
    if (isNew(endpoint)) {
      setData({
        ...data,
        endpoints: data?.endpoints.filter(ep => ep.id !== endpoint.id),
      } as AlgorithmResource)
    } else {
      await onUpdate()
    }
  }
  const createLocalEndpoint = () => {
    const newEndpoint = {
      id:
        sites.length &&
        sites[0].endpoints.length &&
        'new_' + sites[0].endpoints[0].id,
      active: true,
      maximumWeight: '',
      minimumWeight: '',
    }
    setData({
      ...data,
      endpoints: [...data!.endpoints, newEndpoint],
    } as AlgorithmResource)
  }

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <span className="d-inline-block mb-2 mr-2">
          <Modal isOpen size="lg">
            <ModalHeader toggle={back}>Algorithm</ModalHeader>
            <ModalBody>
              <Card className="main-card mb-3">
                <CardHeader>Basic Information</CardHeader>
                <CardBody>
                  <AvForm onValidSubmit={save}>
                    <Row form className="align-items-end">
                      <Col md={12}>
                        <AvGroup className="mb-2">
                          <Label for={`name`}>Name</Label>
                          <AvInput
                            name={`name`}
                            id={`name`}
                            placeholder="enter a name"
                            value={data?.name}
                            onChange={setFormData}
                            required
                          />
                        </AvGroup>
                      </Col>
                      <Col md={12}>
                        <AvGroup className="mb-0">
                          <Label for={`description`}>Description</Label>
                          <AvInput
                            name={`description`}
                            id={`description`}
                            placeholder="enter a description"
                            value={data?.description}
                            onChange={setFormData}
                            required
                          />
                        </AvGroup>
                      </Col>
                      <Col md={12} className="mt-3">
                        <Label for={`active`}>Active</Label>
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <div
                                className="switch has-switch"
                                data-on-label="ON"
                                data-off-label="OFF"
                                id={`active`}
                                onClick={setFormData}
                              >
                                <div
                                  className={cx('switch-animate', {
                                    'switch-on': data?.active,
                                    'switch-off': !data?.active,
                                  })}
                                >
                                  <input type="checkbox" />
                                  <span className="switch-left bg-success">
                                    ON
                                  </span>
                                  <label>&nbsp;</label>
                                  <span className="switch-right bg-success">
                                    OFF
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <Collapse isOpen={changedElem.has(data.id)}>
                          <Button
                            className="mt-2 btn-icon"
                            size="lg"
                            color="primary"
                          >
                            Save
                          </Button>
                        </Collapse>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
              {!isNew(data) && (
                <>
                  <Card className="main-card mb-3">
                    <CardHeader>Kpi's</CardHeader>
                    <CardBody>
                      <Accordion>
                        {data?.kpiGroups?.map(group => (
                          <KpiGroup
                            key={group.id}
                            group={group}
                            Container={AccordionItem}
                            onUpdate={onUpdate}
                            onDelete={onDeleteKpi}
                            onChange={onChange}
                            isNew={isNew(group)}
                            algorithmId={data.id}
                          />
                        ))}
                      </Accordion>
                      <Button
                        className="mb-2 mr-2 btn-icon"
                        size="lg"
                        color="primary"
                        name="_addKpi"
                        onClick={createLocalKpiGroup}
                        hidden={isCreatingKpi}
                      >
                        Add KPI
                      </Button>
                    </CardBody>
                  </Card>
                  <div className="divider" />

                  <Card className="main-card mb-3">
                    <CardHeader>Endpoints</CardHeader>
                    <CardBody>
                      <Accordion>
                        {data?.endpoints?.map((endpoint, index) => {
                          const newEndpoint = isNew(endpoint)
                          let { site, endpoint: detail } = getEndpointFromSite(
                            endpoint.id,
                            sites,
                          )
                          return (
                            <AccordionItem
                              key={index}
                              name={endpoint.id}
                              id={`${index}`}
                              headerChildren={
                                <>
                                  <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                      <div className="widget-content-left">
                                        <div className="widget-heading">
                                          {newEndpoint
                                            ? '[new endpoint]'
                                            : detail
                                            ? `${detail.terminationNumber} - ${detail.name}`
                                            : endpoint.id}
                                          {newEndpoint && (
                                            <div className="badge badge-danger">
                                              NEW
                                            </div>
                                          )}
                                        </div>
                                        <div className="widget-subheading">
                                          {!newEndpoint && site?.name}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                            >
                              {toggle => (
                                <Endpoint
                                  data={endpoint}
                                  onChange={onChange}
                                  onUpdate={onUpdate}
                                  onDelete={onDeleteEndpoint}
                                  toggle={toggle.bind(undefined, index)}
                                  isNew={newEndpoint}
                                />
                              )}
                            </AccordionItem>
                          )
                        })}
                      </Accordion>
                      <Button
                        className="mb-2 mr-2 btn-icon"
                        size="lg"
                        color="primary"
                        name="_addEndpoint"
                        onClick={createLocalEndpoint}
                        hidden={isCreatingEndpoint}
                      >
                        Add Endpoint
                      </Button>
                    </CardBody>
                  </Card>
                </>
              )}
            </ModalBody>

            <SimpleModal
              toggle={() => setNestedModal(false)}
              body="Are you sure you want to discard changes?"
              confirmLabel="Discard"
              confirmAction={backConfirmed}
              isOpen={nestedModal}
            />
          </Modal>
        </span>
      </CSSTransitionGroup>
    </>
  )
}
