import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFetcher } from 'rest-hooks'
import QuicksightdashboardsResource from '../../../resources/quicksightdashboard.ts'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'
import './index.scss'

export default function Dashboards() {
  let { brandId } = useParams()
  let getResource = useFetcher(QuicksightdashboardsResource.detail())

  useEffect(() => {
    ;(async () => {
      const result = await getResource({
        id: '1f31745e-0910-42bd-bfd1-790a9d99cb3c',
        customerId: brandId,
      })
      let containerDiv = document.getElementById('dashboardContainer')
      let dateUntil = new Date();
      let dateFrom = new Date();
      dateFrom.setDate(dateUntil.getDate() - 7);
      // Embeded dashboard options.
      let options = {
        url: result.dashboardEmbedUrl,
        container: containerDiv,
        parameters: {
          // Add parameters that have been defined in the quicksight dashboard. This will cause the filters to be set onload.
          // (watch out that the name of the property has to exactly match with the param defined in the dashboard. This is case sensitive!)
          CustomerId: result.customerId,
          DateFrom: dateFrom.toDateString(),
          DateUntil: dateUntil.toDateString(),
          DateTimeFrom: dateFrom.toDateString(),
          DateTimeUntil: dateUntil.toDateString(),
        },
        scrolling: 'yes',
        height: '100%',
      }

      // Embed the dashboard using AWS module.
      const dashboard = QuickSightEmbedding.embedDashboard(options)

      // Hooks.
      dashboard.on('error', console.error)
      dashboard.on('load', console.error)
    })()
  }, [])

  return <div id="dashboardContainer"></div>
}
