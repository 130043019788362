import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import PageTitle from '../../../Layout/AppMain/PageTitle'
import { Row, Col } from 'reactstrap'
import Cards from './Cards'
import Detail from './Detail'
import { useParams, Route } from 'react-router-dom'
import { useResource } from 'rest-hooks'
import SitesResource from '../../../resources/site'
import { CustomerContext } from '../'

export default function Sites({ match }) {
  let { brandId } = useParams()
  const { results: sites } = useResource(SitesResource.list(), {
    customerid: brandId,
  })
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CustomerContext.Consumer>
          {({ customer }) => (
            <PageTitle
              heading="Sites"
              subheading={`${customer.name} ${
                customer.description?.trim() ? ` - ${customer.description}` : ''
              }`}
              icon="pe-7s-network icon-gradient bg-ripe-malin"
            />
          )}
        </CustomerContext.Consumer>
        <Row className="mt-3">
          <Col md="12">
            <Cards data={sites} match={match} />
          </Col>
        </Row>
      </CSSTransitionGroup>
      <Route
        path={`${match.path}/:siteId`}
        render={routeProps => <Detail parentUrl={match.url} {...routeProps} />}
      />
    </>
  )
}
