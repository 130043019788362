import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

export default function SimpleModal({
  isOpen,
  toggle,
  body,
  confirmLabel,
  confirmAction,
  confirmSemantic = 'danger',
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color={confirmSemantic} onClick={confirmAction}>
          {confirmLabel}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
