export class Settings {
  private oidcAuthority: string = ''
  private oidcClientId: string = ''
  private oidcAudience: string = ''
  private apiBaseUri: string = ''

  constructor() {
    require('dotenv').config()
    this.apiBaseUri = process.env.REACT_APP_APIBASEURI ?? ''
    this.oidcAuthority = process.env.REACT_APP_AUTHORITY ?? ''
    this.oidcClientId = process.env.REACT_APP_CLIENTID ?? ''
    this.oidcAudience = process.env.REACT_APP_AUDIENCE ?? ''
  }

  get getClientId(): string {
    return this.oidcClientId
  }

  get getApiBaseUri(): string {
    return this.apiBaseUri
  }

  get getAudience(): string {
    return this.oidcAudience
  }

  get getAuthority(): string {
    return this.oidcAuthority
  }
}

let settings: Settings
export function getSettings() {
  return settings || (settings = new Settings())
}
