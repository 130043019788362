import React from 'react'
import { Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap'
import { useContext } from 'react'
import { AccordionContext } from './Accordion'

interface Prop {
  name?: string
  id: string
  headerChildren: JSX.Element
  children: (toggle: () => void, current: string) => JSX.Element
}

export default function AccordionItem({
  name,
  id,
  children,
  headerChildren,
}: Prop) {
  const { toggle, current } = useContext(AccordionContext)

  return (
    <Card key={id}>
      <CardHeader id="headingOne" onClick={() => toggle(id)}>
        <Button
          block
          outline
          color="link"
          className="text-left m-0 p-0"
          aria-expanded={current === id}
          aria-controls="collapseOne"
        >
          {headerChildren || <div className="widget-heading">{name}</div>}
        </Button>
      </CardHeader>
      <Collapse
        isOpen={current === id}
        data-parent="#accordion"
        id="collapseOne"
        aria-labelledby="headingOne"
      >
        <CardBody>{children(toggle, current)}</CardBody>
      </Collapse>
    </Card>
  )
}
