import { AbstractInstanceType } from 'rest-hooks/rest'
import AuthedResource from './authedResource'

export default class QuicksightdashboardsResource extends AuthedResource {
  static urlRoot = AuthedResource.baseUrl + '/quicksightdashboards'
  pk() {
    return this.customerId
  }

  readonly customerId: string = ''
  readonly dashboardEmbedUrl: string = ''

  /**
   * Get the url for a Resource
   */
  static url<T extends typeof AuthedResource>(
    this: T,
    urlParams: { customerId: string } & Partial<AbstractInstanceType<T>>,
  ): string {
    if (urlParams && urlParams.customerId) {
      if (this.pk(urlParams) !== undefined) {
        return `${AuthedResource.baseUrl}/quicksightdashboards/1f31745e-0910-42bd-bfd1-790a9d99cb3c?customerId=${urlParams.customerId}`
      }
    }
    // since we're overriding the url() function we must keep the type the
    // same, which means we might not get urlParams
    throw new Error('quicksight require customerId to retrieve')
  }
}
