import React, { useState } from 'react'
import cx from 'classnames'

interface Props {
  value: string
  setValue: (v: string) => {}
}

const SearchBox = ({ value, setValue }: Props) => {
  let [activeSearch, setActiveSearch] = useState(true)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value)

  return (
    <>
      <div
        className={cx('search-wrapper', {
          active: activeSearch,
        })}
      >
        <div className="input-holder">
          <input
            type="text"
            className="search-input"
            placeholder="Type to search"
            value={value}
            onChange={onChange}
          />
          <button
            onClick={() => setActiveSearch(!activeSearch)}
            className="search-icon"
          >
            <span />
          </button>
        </div>
        <button
          onClick={() => setActiveSearch(!activeSearch)}
          className="close"
        />
      </div>
    </>
  )
}

export default SearchBox
