import { Resource } from '@rest-hooks/rest'
import { getSettings } from '../Config/Settings'

export default abstract class AuthedResource extends Resource {
  static accessToken = ''
  static baseUrl =
    process.env.REACT_APP_ENV === 'stub'
      ? 'https://localhost:3001'
      : getSettings().getApiBaseUri
  static async fetch(input: RequestInfo, init: RequestInit) {
    const _init = {
      ...init,
      headers: {
        Authorization: `bearer ${AuthedResource.accessToken}`,
        ...init.headers,
      },
    }
    return await AuthedResource._fetch(input, _init)
  }
  static _fetch = Resource.fetch

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({
      schema: { results: [this], lastPage: '', page: '', pageLength: '' },
    })
  }
}
