import { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Row,
  Col,
} from 'reactstrap'
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation'
import cx from 'classnames'
import { TimeBlock } from '../../../resources/routingPlan'
import Tooltip from 'rc-tooltip'
import Slider from 'rc-slider'
import SimpleModal from '../Shared/Components/SimpleModal'
import { WeightedRoutingPlanResource } from '../../../resources/routingPlan'
import AlgorithmResource from '../../../resources/algorithm'
import { useResource } from 'rest-hooks'
import { observer } from 'mobx-react'
import { useStores } from '../../../Stores/useStores'
import { useParams } from 'react-router-dom'

const handle = props => {
  const { value, dragging, index, ...restProps } = props
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
      overlayStyle={{ zIndex: 2000 }}
    >
      <Slider.Handle value={value} {...restProps} />
    </Tooltip>
  )
}

interface Prop {
  timeBlock: TimeBlock
  toggle: () => void
  onSave: (TimeBlock) => void
}

export default observer(function TimeBlockEditModal({
  timeBlock: block,
  toggle: toggleConfirmed,
  onSave,
}: Prop) {
  const {
    authStore: { user },
  } = useStores()
  const isAdmin = !!user.roles.find(role => role === 'Administrator')

  let [blockLocal, setBlockLocal] = useState(block)
  let [weights, setWeights] = useState(
    block.routingPlan.destinations.reduce(
      (acc, des, index) => Object.assign(acc, { [index]: des.weight }),
      {},
    ),
  )
  let [changed, setChanged] = useState(false)

  let [nestedModal, setNestedModal] = useState(false)
  const toggle = () => (changed ? setNestedModal(true) : toggleConfirmed())

  const algo = block.routingPlan.linkedCruiseControlAlgorithm
    ? useResource(AlgorithmResource.detail(), {
        id: block.routingPlan.linkedCruiseControlAlgorithm,
      })
    : undefined
  let { brandId } = useParams<{ brandId: string }>()

  const { results: algorithms } = useResource(AlgorithmResource.list(), {
    customerid: brandId,
  })

  const save = async () => {
    setChanged(false)
    if (!blockLocal.routingPlan.cruiseControlEnabled) {
      blockLocal.routingPlan.destinations.forEach((des, index) => {
        des.weight = weights[index]
      })
    }
    await onSave(blockLocal)
    toggleConfirmed()
  }

  const setData = e => {
    const [name] = (e.currentTarget.name ?? e.currentTarget.id).split('_')
    const val = e.currentTarget.value

    !changed && setChanged(true)
    switch (name) {
      case 'cruiseControlEnabled':
        setBlockLocal(
          new TimeBlock({
            ...blockLocal,
            routingPlan: WeightedRoutingPlanResource.fromJS({
              ...blockLocal.routingPlan,
              cruiseControlEnabled:
                !blockLocal.routingPlan.cruiseControlEnabled,
            }),
          }),
        )
        break
      case 'algorithm':
        setBlockLocal(
          new TimeBlock({
            ...blockLocal,
            routingPlan: WeightedRoutingPlanResource.fromJS({
              ...blockLocal.routingPlan,
              linkedCruiseControlAlgorithm: val,
            }),
          }),
        )
        break
      default:
        break
    }
  }

  const setWeight = (index, val) => {
    !changed && setChanged(true)
    setWeights({ ...weights, [index]: val })
  }

  return (
    <span className="d-inline-block mb-2 mr-2">
      <Modal size="lg" isOpen>
        <ModalHeader toggle={toggle}>
          Routing Plan: {block.routingPlan.name}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={save}>
            <h6>
              <i className="pe-7s-date btn-icon-wrapper mr-1 font-weight-bold" />
              {`${block.dayOfWeek} ${block.fromDateTime.format(
                'H:mm',
              )} - ${block.untilDateTime.format('H:mm')}`}
            </h6>

            <p className="mb-2">
              {isAdmin ? (
                <Row form className="align-items-end">
                  <Col md={6}>
                    <AvGroup className="mb-0 mt-2">
                      <AvField
                        type="select"
                        helpMessage="Choose an algorithm"
                        name="algorithm"
                        id="algorithm"
                        value={block.routingPlan.linkedCruiseControlAlgorithm}
                        onChange={setData}
                      >
                        <option disabled>Choose an algorithm</option>
                        {algorithms.map(algo => (
                          <option key={algo.id} value={algo.id}>
                            {algo.name}
                          </option>
                        ))}
                      </AvField>
                    </AvGroup>
                  </Col>
                </Row>
              ) : (
                <>
                  <i className="pe-7s-science btn-icon-wrapper mr-1 font-weight-bold" />
                  Algorithm: {algo?.name || 'none'}
                </>
              )}
            </p>
            <div className="divider" />
            <Row form className="align-items-end">
              <Col md={12} className="mb-3">
                <Label for={`cruiseControlEnabled_${block.routingPlan.id}`}>
                  CruiseControl{' '}
                  {blockLocal.routingPlan.cruiseControlEnabled
                    ? 'Enabled'
                    : 'Disabled'}
                </Label>
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left mr-3">
                      <div
                        className="switch has-switch"
                        data-on-label="ON"
                        data-off-label="OFF"
                        id={`cruiseControlEnabled_${block.routingPlan.id}`}
                        onClick={setData}
                      >
                        <div
                          className={cx('switch-animate', {
                            'switch-on':
                              blockLocal.routingPlan.cruiseControlEnabled,
                            'switch-off':
                              !blockLocal.routingPlan.cruiseControlEnabled,
                          })}
                        >
                          <input type="checkbox" />
                          <span className="switch-left bg-success">ON</span>
                          <label>&nbsp;</label>
                          <span className="switch-right bg-success">OFF</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <Label>destinations</Label>
                {block.routingPlan.destinations.map(
                  ({ weight, endpoint }, index) => (
                    <Col key={endpoint.id || endpoint.address} xs={12}>
                      <Row className="mb-2">
                        <Col xs={4} style={{ whiteSpace: 'nowrap' }}>
                          <i className="pe-7s-call btn-icon-wrapper mr-1 font-weight-bold" />
                          {endpoint.address}
                        </Col>
                        <Col xs={4}>
                          <i className="pe-7s-map-marker btn-icon-wrapper mr-1 font-weight-bold" />
                          {endpoint.name}
                        </Col>
                        <Col xs={4}>
                          {blockLocal.routingPlan.cruiseControlEnabled ? (
                            <>
                              <i className="pe-7s-gym btn-icon-wrapper mr-1 font-weight-bold" />
                              {weight}
                            </>
                          ) : (
                            <Slider
                              min={0}
                              max={100}
                              handle={handle}
                              value={weights[index]}
                              onChange={val => setWeight(index, val)}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ),
                )}
              </Col>

              <Col md={12}>
                <div className="divider" />
                <Button
                  className="mb-2 mr-2 btn-icon"
                  size="lg"
                  color="primary"
                  name={`save_${block.routingPlan.id}`}
                  id={`save_${block.routingPlan.id}`}
                  disabled={!changed}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
        <SimpleModal
          toggle={() => setNestedModal(false)}
          body="Are you sure you want to discard changes?"
          confirmLabel="Discard"
          confirmAction={toggleConfirmed}
          isOpen={nestedModal}
        />
      </Modal>
    </span>
  )
})
