import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import classnames from 'classnames'

const RouterLink = props => {
  let location = useLocation()
  const {
    activateMe,
    className,
    classNameActive,
    classNameHasActiveChild,
    active,
    hasActiveChild,
    to,
    externalLink,
    hasSubMenu,
    toggleSubMenu,
    children,
  } = props

  return hasSubMenu || externalLink ? (
    <a
      className={classnames(
        className,
        (hasActiveChild || location.pathname.includes(to)) &&
          classNameHasActiveChild,
        (active || location.pathname === to) && classNameActive,
      )}
      target={externalLink ? '_blank' : undefined}
      href={to}
      onClick={toggleSubMenu}
    >
      {children}
    </a>
  ) : (
    <Link
      className={classnames(
        className,
        active && classNameActive,
        hasActiveChild && classNameHasActiveChild,
      )}
      to={to}
      onClick={hasSubMenu ? toggleSubMenu : activateMe}
    >
      {children}
    </Link>
  )
}

export default RouterLink
