import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import Brands from './Brands'
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'
import ThemeOptions from '../../Layout/ThemeOptions'
import { ToastContainer } from 'react-toastify'
import { Suspense, createContext, useState } from 'react'
import Loader from 'react-loaders'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'

export const CustomerContext = createContext({
  customer: undefined,
  setCustomer: () => {},
})

const SodMain = inject('authStore')(
  observer(({ authStore }) => {
    const [customer, setCustomer] = useState({ id: undefined })
    const { pathname } = useLocation()
    return (
      <>
        {/* {process.env.NODE_ENV !== 'production' ? <ThemeOptions /> : <></>} */}
        <CustomerContext.Provider value={{ customer, setCustomer }}>
          <AppHeader user={authStore.user ?? { name: 'stub' }} />
          <div className="app-main">
            {customer.id && <AppSidebar />}

            <div
              className={cx('app-main__outer', {
                'p-none': !customer.id,
              })}
            >
              <Suspense
                fallback={
                  <div className="loader-container">
                    <div className="loader-container-inner">
                      <div className="text-center">
                        <Loader type="ball-grid-cy" active />
                      </div>
                      <h6 className="mt-3">Please wait</h6>
                    </div>
                  </div>
                }
              >
                <div className="app-main__inner">
                  <Switch>
                    {/* always remove the trailing slash */}
                    <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                    <Route path="/brands" component={Brands} />
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/brands" />}
                    />
                  </Switch>
                </div>
              </Suspense>
            </div>
          </div>
        </CustomerContext.Provider>
        <ToastContainer />
      </>
    )
  }),
)

export default SodMain
