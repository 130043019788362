import { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import MetisMenu from 'react-metismenu'
import { setEnableMobileMenu } from '../../reducers/ThemeOptions'
import { getManagementsNav } from './NavItems'
import { CustomerContext } from '../../CruiseControl/Pages'
import RouterLink from './RouterLink'

function Nav(props) {
  return (
    <Fragment>
      <CustomerContext.Consumer>
        {({ customer }) =>
          customer.id && (
            <>
              <h5 className="app-sidebar__heading">{customer.name}</h5>
              <MetisMenu
                content={getManagementsNav(customer /*, service*/)}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                LinkComponent={RouterLink}
              />
            </>
          )
        }
      </CustomerContext.Consumer>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
})

const mapDispatchToProps = dispatch => ({
  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav))
