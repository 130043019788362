import { Component, createContext } from 'react'
import { Col, Row } from 'reactstrap'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

export const AccordionContext = createContext({ toggle: _ => {}, current: '' })

class AccordionsBasicExample extends Component {
  constructor(props) {
    super(props)
    this.onEntering = this.onEntering.bind(this)
    this.onEntered = this.onEntered.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleFade = this.toggleFade.bind(this)
    this.state = {
      collapse: false,
      accordion: [true, false, false],
      current: '',
      custom: [true, false],
      status: 'Closed',
      fadeIn: true,
      timeout: 300,
    }
  }

  onEntering() {
    this.setState({ status: 'Opening...' })
  }

  onEntered() {
    this.setState({ status: 'Opened' })
  }

  onExiting() {
    this.setState({ status: 'Closing...' })
  }

  onExited() {
    this.setState({ status: 'Closed' })
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse })
  }

  toggleAccordion(tab) {
    const prevState = this.state.accordion
    const state = prevState.map((x, index) => (tab === index ? !x : false))
    this.setState({
      accordion: state,
      current: tab === this.state.current ? '' : tab,
    })
  }

  toggleCustom(tab) {
    const prevState = this.state.custom
    const state = prevState.map((x, index) => (tab === index ? !x : false))

    this.setState({
      custom: state,
    })
  }

  toggleFade() {
    this.setState({ fadeIn: !this.state.fadeIn })
  }

  render() {
    return (
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row className="mt-3">
          <Col md="12">
            <div id="accordion" className="accordion-wrapper mb-3">
              <AccordionContext.Provider
                value={{
                  current: this.state.current,
                  toggle: this.toggleAccordion,
                }}
              >
                {this.props.children}
              </AccordionContext.Provider>
            </div>
          </Col>
        </Row>
      </CSSTransitionGroup>
    )
  }
}

export default AccordionsBasicExample
