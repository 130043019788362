import { useHistory } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'
import { useState } from 'react'
import { AuthenticatedArea, AuthStore, User } from 'sod.it-ops.auth'
import './App.css'
import Main from './CruiseControl/Main'
import { getSettings } from './Config/Settings'
import RootStore from './Stores/RootStore'
import AuthedResource from './resources/authedResource'
import Loader from 'react-loaders'
import CookieConsent from 'react-cookie-consent'

const App = observer(() => {
  const applicationBaseUrl = `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}`
  // const applicationBaseUrl = 'http://yourcustomdomain.internal';

  // Initialize application settings settings from .env file
  const settings = getSettings()

  const [authorized, setAuthorized] = useState<boolean>(false)

  const history = useHistory()
  const onSignIn = (redirectTo: string = '/') => history!.push(redirectTo)
  const onSignOut = () => history!.push('/')
  const onError = e => {
    console.error(e)
    history!.push('/')
  }
  const onSessionExpired = () => history!.push(window.location)
  // history!.push(`/session-expired?url=${window.location}`)

  // Configure and create AuthenticationStore.
  const [authStore] = useState(
    new AuthStore({
      authority: settings.getAuthority,
      audience: settings.getAudience,
      clientId: settings.getClientId,
      baseUri: applicationBaseUrl,
      onSignIn: onSignIn,
      onSignOut: onSignOut,
      onSessionExpired: onSessionExpired,
      onError: onError,
      onAuthenticated: (user: User) => {
        //Request cruise control api token once the user is authenticated successfully.
        authStore!
          .getAccessToken('cruisecontrol-api')
          .then((token: { token: string }) => {
            AuthedResource.accessToken = token.token
            setAuthorized(true)
          })
          .catch(onError)
      },
      apis: [
        {
          // Define apis for which the application is requesting a access token.
          id: 'cruisecontrol-api',
          audience: settings.getAudience,
          scopes: [
            'read:algorithms',
            'update:algorithms',
            'create:algorithms',
            'delete:algorithms',
            'read:kpis',
            'update:kpis',
            'create:kpis',
            'delete:kpis',
            'read:endpoints',
            'update:endpoints',
            'create:endpoints',
            'delete:endpoints',
            'read:kpigroups',
            'update:kpigroups',
            'create:kpigroups',
            'delete:kpigroups',
            'create:endpointdata',
            'read:endpointdata',
            'read:lanes',
            'update:lanes',
            'read:services',
            'update:services',
            'read:customers',
            'read:routingplans',
            'update:routingplans',
            'create:routingplans',
            'delete:routingplans',
            'read:sites',
            'update:sites',
            'create:sites',
            'delete:sites',
            'read:domes',
          ],
        },
      ],
    }),
  )

  // Initialize RootStore
  const [rootStore] = useState(new RootStore(settings))
  // Add authstore to rootStore for later use with methods like logout.
  rootStore!.setAuthstore(authStore)

  const renderLoading = (msg = 'Signing in') => (
    <div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="ball-grid-beat" active />
        </div>
        <h6 className="mt-3">{msg}</h6>
      </div>
    </div>
  )

  const cookieConsent = (
    <CookieConsent
      location="bottom"
      buttonText="OK"
      cookieName="policy_accepted"
      style={{
        background: '#2B373B',
        flexDirection: 'column',
        zIndex: 9999,
      }}
      buttonStyle={{
        color: '#fff',
        fontSize: '0.8rem',
        fontWeight: 500,
        background: '#545cd8',
        borderRadius: '0.3rem',
        padding: '10px 15px',
        marginTop: 0,
      }}
      contentStyle={{ marginBottom: 0, flex: '1 0' }}
    >
      <p>
        This website only uses necessary cookies. Cookies are small text files
        that can be used by websites to make a user's experience more efficient.
        The law states that we can store cookies on your device if they are
        strictly necessary for the operation of this site.
      </p>

      <h4 style={{ fontWeight: 'bold' }}>
        What we collect for Cruise Control and why
      </h4>

      <p>
        Name &amp; Email - Only if you provide it to us in order to use Cruise
        Control
      </p>

      <h4 style={{ fontWeight: 'bold' }}>Who we share with</h4>

      <p>We will never sell your personal data to 3rd parties</p>

      <h4 style={{ fontWeight: 'bold' }}>Details</h4>

      <p>For more information, read our Privacy Policy and Cookie Policy.</p>
    </CookieConsent>
  )

  return (
    <Provider authStore={authStore} rootStore={rootStore}>
      {process.env.REACT_APP_ENV === 'stub' ? (
        <Main />
      ) : (
        <AuthenticatedArea renderSigningIn={renderLoading}>
          {authorized ? <Main /> : renderLoading('Authorizing')}
          {cookieConsent}
        </AuthenticatedArea>
      )}
    </Provider>
  )
})

export default App
