import { Component } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Row, Col, Card, ListGroup, ListGroupItem, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import logo from '../../../assets/utils/images/logo.jpeg'

export default class BrandList extends Component {
  render() {
    const list = this.props.data.map(customer => (
      <ListGroupItem key={customer.id}>
        <Link to={`brands/${customer.id}/services`}>
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left mr-3">
                <img
                  width={42}
                  className="rounded-circle"
                  src={customer.logo || logo}
                  alt=""
                />
              </div>
              <div className="widget-content-left">
                <div className="widget-heading">{customer.name}</div>
                <div className="widget-subheading">{customer.description}</div>
              </div>
              <div className="widget-content-right"></div>
            </div>
          </div>
        </Link>
      </ListGroupItem>
    ))
    return (
      <>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Container fluid className="mt-4">
            <Row>
              <Col lg="12">
                <Card className="main-card mb-3">
                  <ListGroup flush>{list}</ListGroup>
                </Card>
              </Col>
            </Row>
          </Container>
        </CSSTransitionGroup>
      </>
    )
  }
}
