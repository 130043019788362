import AuthedResource from './authedResource'
import moment, { Moment } from 'moment'

export type RoutingPlanType =
  | 'direct'
  | 'overflow'
  | 'time_of_day'
  | 'weighted'
  | 'advanced'

export class TimeBlock {
  constructor({ dayOfWeek, from, until, routingPlan }) {
    this.dayOfWeek = dayOfWeek
    this.from = from
    this.until = until
    this.routingPlan = routingPlan
  }
  dayOfWeek:
    | 'Thursday'
    | 'friday'
    | 'tuesday'
    | 'wednesday'
    | 'monday'
    | 'sunday'
    | 'saturday'
  from: string
  until: string
  routingPlan: WeightedRoutingPlanResource

  get fromDateTime(): Moment {
    return getTime(this.from, this.dayOfWeek)
  }
  get untilDateTime(): Moment {
    return getTime(this.until, this.dayOfWeek)
  }
}

function getTime(time, day) {
  let mtime = moment(time, 'HH:mm:ss')
  return (mtime = moment()
    .isoWeekday(day)
    .hours(mtime.hours())
    .minutes(mtime.minutes())
    .seconds(mtime.seconds()))
}

function toCamel(o) {
  var newO, origKey, newKey, value
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = toCamel(value)
      }
      return value
    })
  } else {
    newO = {}
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString()
        value = o[origKey]
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value)
        }
        newO[newKey] = value
      }
    }
  }
  return newO
}

export default abstract class RoutingPlanResource extends AuthedResource {
  static urlRoot = AuthedResource.baseUrl + '/routingPlans'
  pk() {
    return this.id
  }
  abstract type: RoutingPlanType

  readonly id: string = ''
  readonly customerId: string = ''
  readonly laneId: string = ''
  readonly serviceId: string = ''
  readonly name: string = ''

  /** Perform network request and resolve with json body */
  static fetch(input: RequestInfo, init: RequestInit) {
    init = {
      ...init,
      headers: {
        Authorization: `bearer ${AuthedResource.accessToken}`,
        ...init.headers,
      },
    }
    return this.fetchResponse(input, init).then((response: Response) => {
      if (
        !response.headers.get('content-type')?.includes('json') ||
        response.status === 204
      )
        return response.text()
      return new Promise(res => {
        response
          .json()
          .then(json => res(toCamel(JSON.parse(json))))
          .catch(error => {
            error.status = 400
            throw error
          })
      })
    })
  }
}

export class TimeOfDayRoutingPlanResource extends RoutingPlanResource {
  readonly type: RoutingPlanType = 'time_of_day'
  readonly routingPlanTypeRestrictions: RoutingPlanType[] = []
  readonly timeBlocks: TimeBlock[] = []

  static schema = {
    timeBlocks: [TimeBlock],
  }

  get activeTimeBlock(): TimeBlock | undefined {
    const now = moment()
    return this.timeBlocks.find(tb =>
      now.isBetween(tb.fromDateTime, tb.untilDateTime),
    )
  }
  get nextActiveTimeBlock(): TimeBlock | undefined {
    if (!this.timeBlocks.length) {
      return undefined
    } else if (this.timeBlocks.length === 1) {
      return !this.activeTimeBlock ? this.timeBlocks[0] : undefined
    } else if (this.timeBlocks.length === 2 && this.activeTimeBlock) {
      return this.timeBlocks.find(tb => tb !== this.activeTimeBlock)
    } else {
      let possibleTimeBlocks = this.activeTimeBlock
        ? this.timeBlocks.filter(tb => tb !== this.activeTimeBlock)
        : this.timeBlocks
      const now = moment()
      const nowUnix = now.unix()

      const diffs = possibleTimeBlocks.map(
        tb =>
          (tb.fromDateTime < now
            ? moment(tb.fromDateTime).add(1, 'week')
            : tb.fromDateTime
          ).unix() - nowUnix,
      )

      const index = diffs.reduce(
        (res, val, id, arr) => (val < arr[res] ? id : res),
        0,
      )
      return possibleTimeBlocks[index]
    }
  }
}
export class WeightedRoutingPlanResource extends RoutingPlanResource {
  readonly type: RoutingPlanType = 'weighted'
  readonly cruiseControlEnabled: boolean = false
  readonly linkedCruiseControlAlgorithm: string = 'null'
  readonly destinations: {
    weight: number
    endpoint: {
      name: string
      address: string
      type: string
      id: string
      siteId: string
    }
  }[] = []
}
