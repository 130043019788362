import AuthedResource from './authedResource'

export const getEndpointFromSite = (id, sites) => {
  let endpoint
  let site = sites.find(
    site => (endpoint = site.endpoints.find(endpoint => endpoint.id === id)),
  )
  return { site, endpoint }
}

export default class SiteResource extends AuthedResource implements ISite {
  static urlRoot = AuthedResource.baseUrl + '/sites'
  pk() {
    return this.id
  }

  readonly id: string = ''
  readonly name: string = ''
  readonly customerId: string = ''
  readonly endpoints: IEndpoint[] = []
}

export interface ISite {
  id?: string
  name: string
  customerId: string
  endpoints: IEndpoint[]
}

export interface IEndpoint {
  id?: string
  name: string
  terminationNumber: string
  isActiveInCruiseControl?: true
  linkedAlgorithms?: string[]
  linkedRoutingPlans?: string[]
  lastUpdated?: string
  isHealthy?: true
}
