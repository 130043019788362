import { useState, useEffect } from 'react'
import { Button, Label, Row, Col } from 'reactstrap'
import {
  AvGroup,
  AvInput,
  AvForm,
  AvRadioGroup,
  AvRadio,
  AvField,
} from 'availity-reactstrap-validation'
import cx from 'classnames'
import { useFetcher } from 'rest-hooks'
import { useParams } from 'react-router-dom'
import KpiResource from '../../../resources/kpi'
import Popover from '../Shared/Components/Popover'
import { toast, Slide } from 'react-toastify'
import Tooltip from 'rc-tooltip'
import Slider from 'rc-slider'

const handle = props => {
  const { value, dragging, index, ...restProps } = props
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
      overlayStyle={{ zIndex: 2000 }}
    >
      <Slider.Handle value={value} {...restProps} />
    </Tooltip>
  )
}

const Kpi = ({
  data,
  groupData,
  onDelete,
  onUpdate,
  onChange,
  toggle,
  isNew,
  onCreate,
}) => {
  let { algorithmId } = useParams<{ algorithmId: string }>()

  const updateResource = useFetcher(KpiResource.partialUpdate())
  const deleteResource = useFetcher(KpiResource.delete())
  const createResource = useFetcher(KpiResource.create())

  let [formData, setFormData] = useState(data)
  let [groupFormData, setGroupFormData] = useState(groupData)
  let [changed, _setChanged] = useState(false)
  const setChanged = changed => {
    _setChanged(changed)
    onChange(changed, data.id)
  }

  let [popoverIsOpen, setPopoverIsOpen] = useState(true)
  const save = async () => {
    if (isNew) {
      let newGroup = await onCreate(groupFormData, formData)
      let _formData = { ...formData }
      delete _formData.id
      await createResource({ algorithmId, kpiGroupId: newGroup.id }, _formData)
    } else {
      await updateResource(
        { id: formData.id, algorithmId, kpiGroupId: groupData.id },
        formData,
      )
    }
    setChanged(false)
    await onUpdate(groupFormData)
    toasty()
  }
  const deleteKpiConfirmed = async () => {
    if (!isNew) {
      await deleteResource({
        id: data.id,
        algorithmId,
        kpiGroupId: groupData.id,
      })
    }
    onDelete(groupFormData)
    setPopoverIsOpen(false)
    toggle(data.id)
    !isNew && toasty()
  }

  const setInfluence = val => {
    !changed && setChanged(true)
    setGroupFormData({ ...groupFormData, influence: val })
  }
  const setData = e => {
    const [name, _] = (e.currentTarget.name ?? e.currentTarget.id).split('_')
    const val = e.currentTarget.value

    !changed && setChanged(true)
    switch (name) {
      case 'influence':
        setGroupFormData({ ...groupFormData, [name]: val })
        break
      case 'active':
        setFormData({ ...formData, active: !formData.active })
        break
      default:
        setFormData({ ...formData, [name]: val })
        break
    }
  }

  const toasty = () => {
    toast.success('Success!', {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: 'bottom-center',
      type: 'success',
    })
  }

  useEffect(() => {
    isNew && toggle(data.id)
  }, [])

  return (
    <AvForm onValidSubmit={save}>
      <Row form>
        <Col md={6}>
          <AvGroup>
            <Label for={`name_${data.id}`}>Name</Label>
            <AvInput
              name={`name_${data.id}`}
              id={`name_${data.id}`}
              placeholder="enter a name"
              value={formData.name}
              onChange={setData}
              required
            />
          </AvGroup>
        </Col>
        <Col md={6}>
          <AvField
            id={`displayName_${data.id}`}
            name={`displayName_${data.id}`}
            label="Technical Name"
            placeholder="Please enter a technical name"
            type="text"
            value={formData.displayName}
            onChange={setData}
            validate={{
              required: {
                value: true,
                errorMessage: 'Please enter a technical name',
              },
              pattern: {
                value: '^[A-Za-z0-9_-]+$',
                errorMessage:
                  'Technical name must be composed only with letter and numbers and underscore',
              },
            }}
          />
        </Col>
        <Col md={12}>
          <AvGroup>
            <Label for={`description_${data.id}`}>Description</Label>
            <AvInput
              name={`description_${data.id}`}
              id={`description_${data.id}`}
              placeholder="enter a description"
              value={formData.description}
              onChange={setData}
              required
            />
          </AvGroup>
        </Col>
        <Col md={6}>
          <AvGroup>
            <Label for={`minValue_${data.id}`}>Min</Label>
            <AvInput
              type="Number"
              name={`minValue_${data.id}`}
              id={`minValue_${data.id}`}
              placeholder="enter a min value"
              value={formData.minValue}
              onChange={setData}
              required
            />
          </AvGroup>
        </Col>
        <Col md={6}>
          <AvGroup>
            <Label for={`maxValue_${data.id}`}>Max</Label>
            <AvInput
              type="Number"
              name={`maxValue_${data.id}`}
              id={`maxValue_${data.id}`}
              placeholder="enter a max value"
              value={formData.maxValue}
              onChange={setData}
              required
            />
          </AvGroup>
        </Col>
        <Col md={6}>
          <Label for={`type_${data.id}`}>Type</Label>
          <AvRadioGroup
            inline
            name={`type_${data.id}`}
            id={`type_${data.id}`}
            value={formData.type}
            errorMessage=" "
            required
          >
            <AvRadio label="positive" value="positive" onChange={setData} />
            <AvRadio label="negative" value="negative" onChange={setData} />
          </AvRadioGroup>
        </Col>
        <Col md={6}>
          <Label>Weight</Label>
          <Slider
            className="mb-3"
            min={0}
            max={100}
            handle={handle}
            value={groupFormData.influence}
            onChange={setInfluence}
          />
        </Col>
        <Col md={12}>
          <Label for={`active_${data.id}`}>Active</Label>
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left mr-3">
                <div
                  className="switch has-switch"
                  data-on-label="ON"
                  data-off-label="OFF"
                  id={`active_${data.id}`}
                  onClick={setData}
                >
                  <div
                    className={cx('switch-animate', {
                      'switch-on': formData.active,
                      'switch-off': !formData.active,
                    })}
                  >
                    <input type="checkbox" />
                    <span className="switch-left bg-success">ON</span>
                    <label>&nbsp;</label>
                    <span className="switch-right bg-success">OFF</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={12}>
          <div className="divider" />
          <Button
            className="mb-2 mr-2 btn-icon"
            size="lg"
            color="primary"
            name={`saveKpi_${data.id}`}
            id={`saveKpi_${data.id}`}
            disabled={!changed}
          >
            Save
          </Button>
          <Button
            outline
            className="mb-2 mr-2 btn-icon"
            size="lg"
            color="danger"
            name={`deleteKpi_${data.id}`}
            id={`deleteKpi_${data.id}`}
            onClick={() => null}
          >
            Delete
          </Button>
          {popoverIsOpen ? (
            <Popover
              target={`#deleteKpi_${data.id}`}
              message="Are you sure?"
              confirmLabel="Delete"
              confirmAction={deleteKpiConfirmed}
            />
          ) : (
            <> </>
          )}
        </Col>
      </Row>
    </AvForm>
  )
}

export default Kpi
