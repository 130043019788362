import { Fragment } from 'react'
import {
  Button,
  Label,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap'
import {
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation'
import { SipUri } from 'sod.siplib'
import { PhoneNumber } from '../../../Utils/PhoneNumber'
import { UncontrolledTooltip } from 'reactstrap'

const FormLayout = ({ data, setData }) => {
  const validateNumber = number => {
    if (
      number === undefined ||
      number === '' ||
      number === null ||
      (!PhoneNumber.isValidPhoneNumber(number.trim()) &&
        !new SipUri(number.trim()).isValid)
    ) {
      return false
    }
    return true
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <AvField
              name="__siteName"
              id="_site-name"
              placeholder="enter a name"
              value={data.name}
              onChange={setData}
              label="Name"
              required
            />
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Endpoints</CardTitle>
                {data.endpoints.map(endpoint => (
                  <Fragment key={endpoint.id}>
                    <div className="divider" />
                    <Row form>
                      <Col md={6}>
                        <AvGroup className="mb-0">
                          <Label for={`_${endpoint.id}_eName`}>Name</Label>
                          <AvInput
                            name={`_${endpoint.id}_eName`}
                            id={`_${endpoint.id}_eName`}
                            placeholder="enter a name"
                            value={endpoint.name}
                            onChange={setData}
                            required
                          />
                        </AvGroup>
                      </Col>
                      <Col md={5}>
                        <AvGroup className="mb-0">
                          <Label for={`_${endpoint.id}_eAddress`}>
                            Address (E164 or sip)
                          </Label>
                          <AvInput
                            type="text"
                            name={`_${endpoint.id}_eAddress`}
                            id={`_${endpoint.id}_eAddress`}
                            placeholder="enter a address"
                            value={endpoint.terminationNumber}
                            onChange={setData}
                            required
                            validate={{
                              sync: validateNumber,
                            }}
                          />
                          <AvFeedback>
                            Endpoint address is required and must be in e164
                            format! (ex. sip:31626774721@sip.sod.cloud)
                          </AvFeedback>
                        </AvGroup>
                      </Col>
                      <Col md={1} className="align-self-center">
                        <div id={`div_${endpoint.id}_deleteEndpoint`}>
                          <Button
                            block
                            outline
                            className="btn-icon btn-icon-only px-0 border-0"
                            color="danger"
                            size="lg"
                            name={`_${endpoint.id}_deleteEndpoint`}
                            id={`_${endpoint.id}_deleteEndpoint`}
                            onClick={setData}
                            disabled={
                              endpoint.linkedRoutingPlans?.length > 0 ||
                              endpoint.linkedAlgorithms?.length > 0
                            }
                          >
                            <i className="pe-7s-trash btn-icon-wrapper"> </i>
                          </Button>

                          {endpoint.linkedRoutingPlans?.length > 0 ? (
                            <UncontrolledTooltip
                              placement="auto-start"
                              target={`#div_${endpoint.id}_deleteEndpoint`}
                            >
                              This endpoint is still used in{' '}
                              {endpoint.linkedRoutingPlans?.length}{' '}
                              routingplans. Please remove this endpoint from the
                              routingplans before deleting the endpoint.
                            </UncontrolledTooltip>
                          ) : (
                            endpoint.linkedAlgorithms?.length > 0 && (
                              <UncontrolledTooltip
                                placement="auto-start"
                                target={`#div_${endpoint.id}_deleteEndpoint`}
                              >
                                This endpoint is still used in{' '}
                                {endpoint.linkedAlgorithms?.length} algorithms.
                                Please remove this endpoint from the algorithms
                                before deleting the endpoint.
                              </UncontrolledTooltip>
                            )
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                ))}
                <div className="divider" />

                <Button
                  className="mb-2 mr-2 btn-icon"
                  size="lg"
                  color="primary"
                  name="__addEndpoint"
                  onClick={setData}
                >
                  Add Endpoint
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default FormLayout
