import { PhoneNumberUtil } from 'google-libphonenumber'

export class PhoneNumber {
  private static _phoneUtils = PhoneNumberUtil.getInstance()

  public static isValidPhoneNumber(value: string | undefined): boolean {
    if (value === undefined || value === null || value.trim() === '') {
      return true
    }

    try {
      let phoneNumber = this._phoneUtils.parse('+' + value, '')
      return this._phoneUtils.isValidNumber(phoneNumber)
    } catch (reason) {}
    return false
  }
}
