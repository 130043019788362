import AuthedResource from './authedResource'

export default class LaneResource extends AuthedResource {
  static urlRoot = AuthedResource.baseUrl + '/lanes'
  pk() {
    return this.id
  }

  readonly id: string = ''
  readonly name: string = ''
  readonly description: string = ''
  readonly serviceNumber: string = ''
  readonly hidden: boolean = false
  readonly active: boolean = true
  readonly routingPlans: string[] = []
}
