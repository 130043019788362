import { Component, Fragment } from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

export default class TitleComponent3 extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <i className="pe-7s-ticket" />
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {/* <a href="/brands"> */}
            Brands
            {/* </a> */}
          </BreadcrumbItem>
          {/* <BreadcrumbItem active>Example Breadcrumb</BreadcrumbItem> */}
        </Breadcrumb>
      </Fragment>
    )
  }
}
