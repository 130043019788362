import AuthedResource from './authedResource'

export default class CustomerResource extends AuthedResource {
  static urlRoot = AuthedResource.baseUrl + '/customers'
  pk() {
    return this.id
  }

  readonly id: string = ''
  readonly name: string = ''
  readonly dashboardClientId: string = ''
  readonly description: string = ''
  readonly domeId: string = ''
  readonly fiqasAccountId: string = ''
}
