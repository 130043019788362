import { useState, useEffect } from 'react'
import { useResource, useFetcher } from 'rest-hooks'

import { Button, Label, Row, Col } from 'reactstrap'
import {
  AvGroup,
  AvInput,
  AvForm,
  AvField,
} from 'availity-reactstrap-validation'
import cx from 'classnames'
import { useParams } from 'react-router-dom'
import Popover from '../Shared/Components/Popover'
import { toast, Slide } from 'react-toastify'

import AlgorithmEndpointResource from '../../../resources/algorithmEndpoint'
import SiteResource from '../../../resources/site'

export default function Endpoint({
  data,
  onChange,
  onUpdate,
  onDelete,
  toggle,
  isNew,
}) {
  let { algorithmId, brandId } =
    useParams<{ algorithmId: string; brandId: string }>()

  const { results: sites } = useResource(SiteResource.list(), {
    customerid: brandId,
  })

  const updateResource = useFetcher(AlgorithmEndpointResource.partialUpdate())
  const deleteResource = useFetcher(AlgorithmEndpointResource.delete())
  const createResource = useFetcher(AlgorithmEndpointResource.create())

  let [formData, setFormData] = useState(data)
  let [changed, _setChanged] = useState(false)
  const setChanged = changed => {
    _setChanged(changed)
    onChange(changed, data.id)
  }

  let [popoverIsOpen, setPopoverIsOpen] = useState(true)
  const save = async () => {
    if (isNew) {
      await createResource(
        { algorithmId },
        {
          ...formData,
          id: formData.id.startsWith('new')
            ? formData.id.split('_')[1]
            : formData.id,
        },
      )
    } else {
      await updateResource({ id: formData.id, algorithmId }, formData)
      setChanged(false)
    }
    onUpdate()
    toggle(data.id)
    toasty()
  }
  const deleteConfirmed = async () => {
    if (!isNew) {
      await deleteResource({ id: data.id, algorithmId })
    }
    onDelete()
    setPopoverIsOpen(false)
    toggle(data.id)
    !isNew && toasty()
  }
  const setData = e => {
    const [name, _] = (e.currentTarget.name ?? e.currentTarget.id).split('_')
    const val = e.currentTarget.value

    setChanged(true)
    switch (name) {
      case 'active':
        setFormData({ ...formData, active: !formData.active })
        break
      default:
        setFormData({ ...formData, [name]: val })
        break
    }
  }

  const toasty = () => {
    toast.success('Success!', {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: 'bottom-center',
      type: 'success',
    })
  }

  useEffect(() => {
    isNew && toggle(data.id)
  }, [])

  return (
    <AvForm onValidSubmit={save}>
      <Row form className="align-items-end">
        <Col md={12}>
          <AvGroup className="mb-0">
            <Label for={`name_${data.id}`}>Endpoint</Label>
            <AvField
              type="select"
              helpMessage="Choose an endpoint"
              name={`id_${data.id}`}
              id={`id_${data.id}`}
              value={data.id}
              onChange={setData}
            >
              <option disabled>Choose an endpoint</option>
              {sites.flatMap(site =>
                site.endpoints.map(endpoint => (
                  <option
                    key={endpoint.id}
                    value={endpoint.id}
                  >{`${site.name} - ${endpoint.name} (${endpoint.terminationNumber})`}</option>
                )),
              )}
            </AvField>
          </AvGroup>
        </Col>
        <Col md={6}>
          <AvGroup className="mb-0">
            <Label for={`minimumWeight_${data.id}`}>Minimum Weight</Label>
            <AvInput
              type="Number"
              name={`minimumWeight_${data.id}`}
              id={`minimumWeight_${data.id}`}
              placeholder="enter a min weight"
              value={formData.minimumWeight}
              onChange={setData}
              required
            />
          </AvGroup>
        </Col>
        <Col md={6}>
          <AvGroup className="mb-0">
            <Label for={`maximumWeight_${data.id}`}>Maximum Weight</Label>
            <AvInput
              type="Number"
              name={`maximumWeight_${data.id}`}
              id={`maximumWeight_${data.id}`}
              placeholder="enter a max weight"
              value={formData.maximumWeight}
              onChange={setData}
              required
            />
          </AvGroup>
        </Col>
        <Col md={12} className="mt-3">
          <Label for={`active_${data.id}`}>Active</Label>
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left mr-3">
                <div
                  className="switch has-switch"
                  data-on-label="ON"
                  data-off-label="OFF"
                  id={`active_${data.id}`}
                  onClick={setData}
                >
                  <div
                    className={cx('switch-animate', {
                      'switch-on': formData.active,
                      'switch-off': !formData.active,
                    })}
                  >
                    <input type="checkbox" />
                    <span className="switch-left bg-success">ON</span>
                    <label>&nbsp;</label>
                    <span className="switch-right bg-success">OFF</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={12}>
          <div className="divider" />
          <Button
            className="mb-2 mr-2 btn-icon"
            size="lg"
            color="primary"
            name={`save_${data.id}`}
            id={`save_${data.id}`}
            disabled={!changed}
          >
            Save
          </Button>
          <Button
            outline
            className="mb-2 mr-2 btn-icon"
            size="lg"
            color="danger"
            name={`delete_${data.id}`}
            id={`delete_${data.id}`}
            onClick={() => null}
          >
            Delete
          </Button>
          {popoverIsOpen && (
            <Popover
              target={`#delete_${data.id}`}
              message="Are you sure?"
              confirmLabel="Delete"
              confirmAction={deleteConfirmed}
            />
          )}
        </Col>
      </Row>
    </AvForm>
  )
}
