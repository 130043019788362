import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Link } from 'react-router-dom'
import { Row, Col, Card, CardBody, CardHeader, Container } from 'reactstrap'

export default function CardsAdvanced({ data }) {
  const items =
    data &&
    data.map(service => (
      <Col key={service.id} md="4">
        <Link
          to={`services/${service.id}/lanes`}
          style={{ textDecoration: 'none', color: 'initial' }}
        >
          <Card className="main-card mb-3 card-hover-shadow-2x card-border">
            <CardHeader>{service.name}</CardHeader>
            <CardBody>
              <p className="text-muted mb-1">{service.description}</p>
              <p className="mb-0 text-muted">{service.lanes?.length} lanes</p>
            </CardBody>
          </Card>
        </Link>
      </Col>
    ))
  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear={true}
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <Container fluid>
        <Row className="mt-3">{items}</Row>
      </Container>
    </CSSTransitionGroup>
  )
}
