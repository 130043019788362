import AuthedResource from './authedResource'
import { AbstractInstanceType } from 'rest-hooks/rest'

export default class EndpointResource extends AuthedResource {
  static get key() {
    return 'EndpointResource'
  }

  pk() {
    return this.id
  }

  readonly id: string = ''
  readonly name: string = ''
  readonly terminationNumber: string = ''
  readonly isActiveInCruiseControl: boolean = false
  readonly isHealthy: boolean = true
  readonly linkedAlgorithms: string[] = []
  readonly linkedRoutingPlans: string[] = []
  readonly lastUpdated: Date = new Date(0)

  /**
   * Get the url for a Resource
   */
  static url<T extends typeof AuthedResource>(
    this: T,
    urlParams: { siteId: string } & Partial<AbstractInstanceType<T>>,
  ): string {
    if (urlParams && urlParams.siteId) {
      if (this.pk(urlParams) !== undefined) {
        return `${AuthedResource.baseUrl}/sites/${
          urlParams.siteId
        }/endpoints/${this.pk(urlParams)}`
      }
    }
    // since we're overriding the url() function we must keep the type the
    // same, which means we might not get urlParams
    throw new Error('Endpoint require siteId to retrieve')
  }

  /**
   * Get the url for many Resources
   */
  static listUrl(searchParams: { siteId: string }): string {
    if (searchParams && searchParams.siteId) {
      const { siteId, ...realSearchParams } = searchParams
      const params = new URLSearchParams(realSearchParams as any)
      // this is essential for consistent url strings
      params.sort()
      return `${
        AuthedResource.baseUrl
      }/sites/${siteId}/endpoints/?${params.toString()}`
    }
    throw new Error('Endpoint require siteId to retrieve')
  }
}
