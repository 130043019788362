import { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from 'reactstrap'
import WeekScheduleModal from './WeekScheduleModal'
import TimeBlockEditModal from './TimeBlockEditModal'
import {
  TimeOfDayRoutingPlanResource,
  TimeBlock,
} from '../../../resources/routingPlan'
import AlgorithmResource from '../../../resources/algorithm'
import { useFetcher, useResource } from 'rest-hooks'
import _ from 'lodash'
import { toast } from 'react-toastify'

interface Props {
  routingPlan: TimeOfDayRoutingPlanResource
}

export default function RoutingPlan({ routingPlan }: Props) {
  const [scheduleModalToggle, setScheduleModalModalToggle] = useState(false)
  const [editModalToggle, setEditModalModalToggle] = useState<false | number>(
    false,
  )

  const updateResource = useFetcher(TimeOfDayRoutingPlanResource.update())
  // const updateSingleResource = useFetcher(TimeOfDayRoutingPlanResource.detail())

  const save = async block => {
    const _routingPlan = { ...routingPlan }
    _routingPlan.timeBlocks[editModalToggle as number] = block
    await updateResource({ id: _routingPlan.id }, _routingPlan)
    toast.success('Success!')
    // await updateSingleResource({ id: routingPlan.id })
  }

  const detail = ({
    timeBlock: { routingPlan, fromDateTime, dayOfWeek, untilDateTime },
    index,
  }: {
    timeBlock: TimeBlock
    index: number
  }) => {
    let algo
    try {
      algo = routingPlan.linkedCruiseControlAlgorithm
        ? useResource(AlgorithmResource.detail(), {
            id: routingPlan.linkedCruiseControlAlgorithm,
          })
        : undefined
    } catch (e) {
      algo = { name: 'not found', e }
    }

    return (
      <>
        <Col sm={6}>
          <div>{routingPlan.name}</div>
        </Col>
        <Col sm={6} className="mb-2">
          <div>
            <i className="pe-7s-date btn-icon-wrapper mr-1 font-weight-bold" />
            {`${dayOfWeek} ${fromDateTime.format(
              'H:mm',
            )} - ${untilDateTime.format('H:mm')}`}
          </div>
        </Col>
        <Col sm={12}>
          <div className="divider mt-0 mb-2" />
        </Col>
        {routingPlan.destinations.map(({ weight, endpoint }) => (
          <Col
            key={`${endpoint.id}-${endpoint.address}`}
            xs={12}
            className="mb-2 text-nowrap"
          >
            <Row>
              <Col xs={4}>
                <i className="pe-7s-call btn-icon-wrapper mr-1 font-weight-bold" />
                {endpoint.address}
              </Col>
              <Col xs={5}>
                <i className="pe-7s-map-marker btn-icon-wrapper mr-1 font-weight-bold" />
                {endpoint.name}
              </Col>
              <Col xs={3}>
                <i className="pe-7s-gym btn-icon-wrapper mr-1 font-weight-bold" />
                {weight}
              </Col>
            </Row>
          </Col>
        ))}
        <Col sm={12}>
          <div className="divider mt-0 mb-2" />

          <p className="mb-2">Algorithm: {algo?.name || 'none'}</p>
        </Col>
        <Col sm={12}>
          <Button
            outline
            size="sm"
            color="secondary"
            className="mr-2"
            onClick={() => setEditModalModalToggle(index)}
          >
            Edit
          </Button>
        </Col>
      </>
    )
  }

  return (
    <Card key={routingPlan.id} className="main-card mb-3 card-hover-shadow-2x">
      <CardHeader>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <div className="widget-heading">{routingPlan.name}</div>
              <div className="widget-subheading d-none d-lg-block">
                {_.capitalize(routingPlan.type).replaceAll('_', ' ')}
              </div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg={6}>
            {routingPlan.activeTimeBlock ? (
              <Row>
                <Col xs={12} className="mb-2">
                  <div className="badge badge-primary">Active routing</div>
                  {routingPlan.activeTimeBlock.routingPlan
                    .cruiseControlEnabled ? (
                    <div className="badge badge-success ml-2">
                      CruiseControl ON
                    </div>
                  ) : (
                    <div className="badge badge-danger ml-2">
                      CruiseControl OFF
                    </div>
                  )}
                </Col>
                {detail({
                  timeBlock: routingPlan.activeTimeBlock,
                  index: routingPlan.timeBlocks.indexOf(
                    routingPlan.activeTimeBlock,
                  ),
                })}
              </Row>
            ) : (
              <div>There are no active routing</div>
            )}
          </Col>
          <div className="col-12 divider d-block d-lg-none" />
          <Col lg={6}>
            {routingPlan.nextActiveTimeBlock && (
              <Row className="text-muted">
                <Col xs={12} className="mb-2">
                  <div className="badge badge-secondary">Upcoming routing</div>
                  {routingPlan.nextActiveTimeBlock.routingPlan
                    .cruiseControlEnabled ? (
                    <div className="badge badge-success ml-2">
                      CruiseControl ON
                    </div>
                  ) : (
                    <div className="badge badge-danger ml-2">
                      CruiseControl OFF
                    </div>
                  )}
                </Col>
                {detail({
                  timeBlock: routingPlan.nextActiveTimeBlock,
                  index: routingPlan.timeBlocks.indexOf(
                    routingPlan.nextActiveTimeBlock,
                  ),
                })}
              </Row>
            )}
          </Col>
        </Row>
        <div className="divider" />
        <p className="mb-0">Routing Plan ID: {routingPlan.id}</p>
      </CardBody>
      <CardFooter className="d-block text-left">
        <Button
          outline
          size="lg"
          color="info"
          className="mr-2"
          onClick={() => setScheduleModalModalToggle(true)}
        >
          View Week Schedule
        </Button>
        {scheduleModalToggle && (
          <WeekScheduleModal
            routingPlan={routingPlan}
            toggle={() => setScheduleModalModalToggle(false)}
            onSelectTimeBlock={index => setEditModalModalToggle(index)}
          />
        )}
        {editModalToggle !== false && (
          <TimeBlockEditModal
            toggle={() => setEditModalModalToggle(false)}
            timeBlock={routingPlan.timeBlocks[editModalToggle]}
            onSave={save}
          />
        )}
      </CardFooter>
    </Card>
  )
}
