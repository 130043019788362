import './polyfills'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import './assets/base.scss'
import App from './App'
import configureStore from './Config/configureStore'
import { Provider } from 'react-redux'
import { CacheProvider } from 'rest-hooks'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'

const store = configureStore()
const rootElement = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <CacheProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CacheProvider>
  </Provider>,
  rootElement,
)

// if (module.hot) {
//   module.hot.accept('./DemoPages/Main', () => {
//     const NextApp = require('./DemoPages/Main').default;
//     renderApp(NextApp);
//   });
// }
serviceWorker.unregister()

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
