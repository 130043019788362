import React, { Fragment } from 'react'
import cx from 'classnames'

import { connect } from 'react-redux'

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import HeaderLogo from '../AppLogo'

// import MegaMenu from './Components/MegaMenu';
import UserBox from './Components/UserBox'
// import HeaderRightDrawer from './Components/HeaderRightDrawer';

// import HeaderDots from './Components/HeaderDots';

class Header extends React.Component {
  render() {
    let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } =
      this.props
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          className={cx('app-header', headerBackgroundColor, {
            'header-shadow': enableHeaderShadow,
          })}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <HeaderLogo />
          <div
            className={cx('app-header__content', {
              'header-mobile-open': enableMobileMenuSmall,
            })}
          >
            <div className="app-header-left">{/* <MegaMenu /> */}</div>
            <div className="app-header-right">
              {/* <HeaderDots /> */}
              <UserBox user={this.props.user} />
              {/* <HeaderRightDrawer /> */}
            </div>
          </div>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
