import { Route, Switch } from 'react-router-dom'
import Services from '../Services'
import Sites from '../Sites'
import Algorithms from '../Algorithms'
import Lanes from '../Lanes'
import Lane from '../Lane'
import Dashboards from '../Dashboards'
import { useParams } from 'react-router-dom'
import { useEffect, useContext } from 'react'
import { CustomerContext } from '..'
import { useResource } from 'rest-hooks'
import CustomerResource from '../../../resources/customer'

export default function Brands({ match }) {
  let { brandId } = useParams()
  const { customer, setCustomer } = useContext(CustomerContext)
  const customerResource = useResource(CustomerResource.detail(), {
    id: brandId,
  })
  useEffect(() => {
    if (customer && customer.id !== brandId) {
      setCustomer({ ...customerResource })
    }
    return () => setCustomer({ id: undefined })
  }, [])
  return (
    <>
      <Switch>
        <Route
          path={`${match.path}/services/:serviceId/lanes/:laneId`}
          component={Lane}
        />
        <Route path={`${match.path}/services/:serviceId`} component={Lanes} />
        <Route path={`${match.path}/algorithms`} component={Algorithms} />
        <Route path={`${match.path}/sites`} component={Sites} />
        <Route path={`${match.path}/dashboards`} component={Dashboards} />
        <Route path={`${match.path}`} component={Services} />
      </Switch>
    </>
  )
}
