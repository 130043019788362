import { AbstractInstanceType } from 'rest-hooks/rest'
import AuthedResource from './authedResource'

export default class AlgorithmEndpointResource extends AuthedResource {
  pk() {
    return this.id
  }

  readonly id = ''
  readonly active = true
  readonly minimumWeight = 0
  readonly maximumWeight = 0

  static get key() {
    return 'AlgorithmEndpointResource'
  }

  /**
   * Get the url for a Resource
   */
  static url<T extends typeof AuthedResource>(
    this: T,
    urlParams: { algorithmId: string } & Partial<AbstractInstanceType<T>>,
  ): string {
    if (urlParams && urlParams.algorithmId) {
      if (this.pk(urlParams) !== undefined) {
        return `${AuthedResource.baseUrl}/algorithms/${
          urlParams.algorithmId
        }/endpoints/${this.pk(urlParams)}`
      }
    }
    // since we're overriding the url() function we must keep the type the
    // same, which means we might not get urlParams
    throw new Error('Endpoint require algorithmId to retrieve')
  }

  /**
   * Get the url for many Resources
   */
  static listUrl(searchParams: { algorithmId: string }): string {
    if (searchParams && searchParams.algorithmId) {
      const { algorithmId, ...realSearchParams } = searchParams
      const params = new URLSearchParams(realSearchParams as any)
      // this is essential for consistent url strings
      params.sort()
      return `${
        AuthedResource.baseUrl
      }/algorithms/${algorithmId}/endpoints/?${params.toString()}`
    }
    throw new Error('Endpoint require algorithmId to retrieve')
  }
}
