import { useState } from 'react'
import { Modal } from 'reactstrap'
import { UncontrolledTooltip } from 'reactstrap'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/en-gb'
import './WeekScheduleModal.scss'
import { TimeOfDayRoutingPlanResource } from '../../../resources/routingPlan'
import AlgorithmResource from '../../../resources/algorithm'
import { useParams } from 'react-router-dom'
import { useResource } from 'rest-hooks'

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
moment.locale('en-gb')
const toEvents = (routingPlan: TimeOfDayRoutingPlanResource) =>
  routingPlan.timeBlocks.map((block, index) => {
    return {
      index,
      title: block.routingPlan.name,
      start: block.fromDateTime.toDate(),
      end: block.untilDateTime.toDate(),
      id: block.routingPlan.id,
      routingPlan: block.routingPlan,
    }
  })

export default function WeekScheduleModal({
  routingPlan,
  toggle,
  onSelectTimeBlock,
}) {
  const [events, _] = useState(toEvents(routingPlan))
  const onSelect = event => {
    onSelectTimeBlock(event.index)
  }

  let { brandId } = useParams<{ brandId: string }>()

  const { results: algorithms } = useResource(AlgorithmResource.list(), {
    customerid: brandId,
  })

  return (
    <span className="d-inline-block mb-2 mr-2">
      <Modal size="lg" isOpen toggle={toggle}>
        <Calendar
          events={events}
          localizer={localizer}
          defaultView="week"
          views={['week']}
          step={15}
          timeslots={4}
          toolbar={false}
          messages={{ allDay: '' }}
          defaultDate={new Date()}
          style={{ height: '90vh' }}
          showMultiDayTimes
          onSelectEvent={onSelect}
          components={{
            event: props => {
              return (
                <>
                  <div
                    id={'event-' + props.event.id}
                    style={{ height: '100%' }}
                  ></div>
                  <UncontrolledTooltip
                    placement="auto-start"
                    target={'event-' + props.event.id}
                  >
                    <p>
                      {'Cruise Control ' +
                        (props.event.routingPlan.cruiseControlEnabled
                          ? 'Enabled'
                          : 'Disabled')}
                    </p>
                    <p>
                      {'Algorithm: ' +
                        (algorithms.find(
                          algo =>
                            algo.id ===
                            props.event.routingPlan
                              .linkedCruiseControlAlgorithm,
                        )?.name || 'None')}
                    </p>
                    <span>Destinations</span>
                    <ul>
                      {props.event.routingPlan.destinations.map(des => (
                        <li key={des.endpoint.address}>
                          {des.endpoint.name} - {des.endpoint.address} -{' '}
                          {des.weight}
                        </li>
                      ))}
                    </ul>
                  </UncontrolledTooltip>
                </>
              )
            },
          }}
        />
      </Modal>
    </span>
  )
}
