import { useState } from 'react'
import Kpi from './Kpi'
import KpiGroupResource from '../../../resources/kpiGroup'
import { useFetcher } from 'rest-hooks'

interface Prop {
  group: KpiGroupResource
  Container: ({
    id,
    children,
    headerChildren,
  }: {
    id: string
    headerChildren: JSX.Element
    children: (toggle: () => void, current: string) => JSX.Element
  }) => JSX.Element
  onUpdate: (a: KpiGroupResource) => void
  onDelete: (a: KpiGroupResource) => void
  onChange: (boolean, string) => void
  isNew: boolean
  algorithmId: string
}

export default function KpiGroup({
  group,
  Container,
  onUpdate,
  onDelete,
  onChange,
  isNew,
  algorithmId,
}: Prop) {
  let [groupData, setGroupData] = useState(group)

  const updateResource = useFetcher(KpiGroupResource.partialUpdate())
  const deleteResource = useFetcher(KpiGroupResource.delete())
  const createResource = useFetcher(KpiGroupResource.create())

  const onGroupDelete = async () => {
    if (group.kpIs.length === 1 && !isNew) {
      await deleteResource({ algorithmId, id: group.id })
    }
    onDelete(group)
  }
  const onGroupUpdate = async newData => {
    if (newData.influence !== group.influence && !isNew) {
      await updateResource(
        { algorithmId, id: group.id },
        { influence: newData.influence },
      )
    }
    onUpdate(group)
  }
  const onGroupCreate = async (newGroupData, newKpiData) => {
    const res = await createResource(
      { algorithmId },
      {
        influence: newGroupData.influence,
        name: newKpiData.name,
        description: newKpiData.description,
        active: true,
      },
    )
    setGroupData(res)
    return res
  }
  return (
    <>
      {group?.kpIs?.map(kpi => (
        <Container
          key={kpi.id}
          id={kpi.id}
          headerChildren={
            <>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="widget-heading">
                      {isNew ? '[new kpi]' : kpi.displayName}
                      {isNew && <div className="badge badge-danger">NEW</div>}
                    </div>
                    <div className="widget-subheading">{kpi.name}</div>
                  </div>
                </div>
              </div>
            </>
          }
        >
          {toggle => (
            <Kpi
              data={kpi}
              groupData={groupData}
              onCreate={onGroupCreate}
              onUpdate={onGroupUpdate}
              onDelete={onGroupDelete}
              onChange={onChange}
              toggle={toggle}
              isNew={isNew}
            />
          )}
        </Container>
      ))}
    </>
  )
}
