import {
  Button,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap'

export default function PopoverItem({
  message,
  target,
  confirmLabel,
  confirmAction,
}) {
  return (
    <UncontrolledPopover
      fade={false}
      placement="top"
      trigger="legacy"
      target={target}
    >
      <PopoverHeader>{message}</PopoverHeader>
      <PopoverBody>
        <Button className="mr-2 mb-2" color="primary" onClick={confirmAction}>
          {confirmLabel}
        </Button>
      </PopoverBody>
    </UncontrolledPopover>
  )
}
