import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import PageTitle from '../../../Layout/AppMain/PageTitle'
import Cards from './Cards'
import { useResource } from 'rest-hooks'
import LaneResource from '../../../resources/lane'
import ServiceResource from '../../../resources/service'
import { useParams } from 'react-router-dom'

export default function Service() {
  const { brandId, serviceId } = useParams()

  const { results: lanes } = useResource(LaneResource.list(), {
    customerid: brandId,
    serviceId,
  })
  const service = useResource(ServiceResource.detail(), {
    id: serviceId,
  })
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Lanes"
          subheading={service.name}
          icon="pe-7s-tools icon-gradient bg-ripe-malin"
        />
        <Cards data={lanes} />
      </CSSTransitionGroup>
    </>
  )
}
