import { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Brand from '../Brand'
import PageTitle from '../../../Layout/AppMain/PageTitle'
import { Row } from 'reactstrap'
import ListGroup from './ListGroup'
import SearchBox from '../../../Layout/AppHeader/Components/SearchBox'
import { useResource } from 'rest-hooks'
import CustomerResource from '../../../resources/customer'
import { NetworkErrorBoundary } from 'rest-hooks'
import { Link } from 'react-router-dom'

export default function Brands({ match }) {
  const selectBrand = () => {
    const [searchValue, setSearchValue] = useState('')
    let { results: customers } = useResource(CustomerResource.list(), {
      pageLength: 330,
    })

    if (searchValue) {
      customers = customers.filter(c =>
        c.name
          .toLowerCase()
          .match(
            new RegExp(
              `.*${searchValue.toLowerCase().split('').join('.*')}.*`,
              'i',
            ),
          ),
      )
    }

    return (
      <>
        <PageTitle
          heading="Brands"
          subheading="Marvelous Multi-Brand Strategy"
          icon="pe-7s-ticket icon-gradient bg-mixed-hopes"
        />
        <Row className="mt-3">
          <SearchBox value={searchValue} setValue={setSearchValue} />
        </Row>
        <ListGroup data={customers} />
      </>
    )
  }

  return (
    <>
      <NetworkErrorBoundary
        fallbackComponent={({ error }) => {
          return (
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <h6 className="mt-3">{`Network Error (${error.status})`}</h6>
                  <Link to="/">Go Home</Link>
                </div>
              </div>
            </div>
          )
        }}
      >
        <Switch>
          <Route path={`${match.url}/:brandId`} component={Brand} />
          <Route path={`${match.url}`} component={selectBrand} />
        </Switch>
      </NetworkErrorBoundary>
    </>
  )
}
