import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import PageTitle from '../../../Layout/AppMain/PageTitle'
import RoutingPlans from './RoutingPlans'
import { useResource } from 'rest-hooks'
import LaneResource from '../../../resources/lane'
import { useParams } from 'react-router-dom'

export default function Lane() {
  let { laneId } = useParams()

  const lane = useResource(LaneResource.detail(), { id: laneId })
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={lane.name}
          subheading={lane.serviceNumber}
          icon="pe-7s-call icon-gradient bg-ripe-malin"
        />
        <RoutingPlans />
      </CSSTransitionGroup>
    </>
  )
}
