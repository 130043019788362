import { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import SiteForm from './Form'
import { useParams, useHistory } from 'react-router-dom'
import { useResource, useFetcher } from 'rest-hooks'
import SiteResource, { ISite, IEndpoint } from '../../../resources/site'
import EndpointResource from '../../../resources/endpoint'
import SimpleModal from '../Shared/Components/SimpleModal'

const serializeSiteResource = (site: ISite, create: boolean) => {
  const res = {} as ISite
  res.name = site.name
  if (!create) res.id = site.id
  res.customerId = site.customerId
  res.endpoints = site.endpoints.map(({ id, name, terminationNumber }) => {
    const res: IEndpoint = { name, terminationNumber }
    if (id && !id.startsWith('new')) res.id = id
    return res
  })
  return JSON.stringify(res)
}

const Detail = ({ parentUrl }) => {
  let { brandId, siteId } = useParams<{
    brandId: string
    siteId: string
  }>()

  const create = siteId === 'new'

  const site = !create
    ? useResource(SiteResource.detail(), {
        customerid: brandId,
        id: siteId,
      })
    : ({ name: '', customerId: brandId, endpoints: [] as IEndpoint[] } as ISite)

  const deleteSiteResource = useFetcher(SiteResource.delete())
  const updateSiteResource = useFetcher(SiteResource.update())
  const createSiteResource = useFetcher(SiteResource.create())
  const getSiteResource = useFetcher(SiteResource.list())
  const deleteEndpointResource = useFetcher(EndpointResource.delete())

  let [formData, setFormData] = useState(site)
  let [changed, setChanged] = useState(false)
  let [deleted] = useState<string[]>([])
  const setData = e => {
    setChanged(true)
    let endpoints
    const [, id, name] = e.currentTarget.name.split('_')
    const val = e.currentTarget.value
    switch (name) {
      case 'siteName':
        setFormData({ ...formData, name: val } as SiteResource)
        break
      case 'eName':
        endpoints = formData.endpoints.map(e =>
          e.id === id ? { ...e, name: val } : e,
        )
        setFormData({ ...formData, endpoints } as SiteResource)
        break
      case 'eAddress':
        endpoints = formData.endpoints.map(e =>
          e.id === id ? { ...e, terminationNumber: val } : e,
        )
        setFormData({ ...formData, endpoints } as SiteResource)
        break
      case 'deleteEndpoint':
        endpoints = formData.endpoints.filter(e => e.id !== id)
        id.startsWith('new') && deleted.push(id)
        setFormData({ ...formData, endpoints } as SiteResource)
        break
      case 'addEndpoint':
        setFormData({
          ...formData,
          endpoints: formData.endpoints.concat({
            id: `new-${Date.now()}`,
          } as IEndpoint),
        } as SiteResource)
        break
    }
  }

  const history = useHistory()
  const backConfirmed = () => history.push(parentUrl)

  const cancel = () => (changed ? discard() : backConfirmed())

  const save = async () => {
    create
      ? await createSiteResource({}, serializeSiteResource(formData, true))
      : await updateSiteResource(
          { id: formData.id },
          serializeSiteResource(formData, false),
        )
    if (deleted.length) {
      deleted.forEach(async id => await deleteEndpointResource({ id, siteId }))
    }

    getSiteResource({ customerid: brandId })
    backConfirmed()
  }

  let [nestedModal, setNestedModal] = useState({
    isOpen: false,
    body: '',
    confirmLabel: '',
    confirmAction: () => {},
  })
  const toggleNested = () => {
    setNestedModal({ ...nestedModal, isOpen: !nestedModal.isOpen })
  }
  const deleteSite = () => {
    setNestedModal({
      isOpen: true,
      body: 'Are you sure you want to delete this site?',
      confirmLabel: 'Delete',
      confirmAction: async () => {
        backConfirmed()
        await deleteSiteResource({ id: site.id })
        getSiteResource({ customerid: brandId })
      },
    })
  }
  const discard = () => {
    setNestedModal({
      isOpen: true,
      body: 'Are you sure you want to discard changes?',
      confirmLabel: 'Discard',
      confirmAction: history.goBack,
    })
  }

  return (
    <span className="d-inline-block mb-2 mr-2">
      <Modal isOpen size="lg">
        <AvForm onValidSubmit={save}>
          <ModalHeader toggle={cancel}>Site</ModalHeader>
          <ModalBody>
            <SiteForm data={formData} setData={setData} />
          </ModalBody>
          <ModalFooter>
            <Button outline color="danger" onClick={deleteSite}>
              Delete
            </Button>
            <Button color="primary" disabled={!changed}>
              Save
            </Button>
            <SimpleModal toggle={toggleNested} {...nestedModal} />
          </ModalFooter>
        </AvForm>
      </Modal>
    </span>
  )
}

export default Detail
