import { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
} from 'reactstrap'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import RoutingPlan from './RoutingPlan'
import { TimeOfDayRoutingPlanResource } from '../../../resources/routingPlan'
import { useParams } from 'react-router-dom'
import { useResource } from 'rest-hooks'

export default function RoutingPlans() {
  let { laneId } = useParams<{ laneId: string }>()
  let { results: routingPlans } = useResource(
    TimeOfDayRoutingPlanResource.list(),
    { laneId },
  )

  let [state, setState] = useState({
    collapse: false,
    accordion: [true],
    current: 0,
    custom: [true, false],
    status: 'Opened',
    fadeIn: true,
    timeout: 300,
  })

  const toggleAccordion = tab => {
    const accordion = state.accordion.map((x, index) =>
      tab === index ? !x : false,
    )
    setState({ ...state, accordion, current: tab === state.current ? -1 : tab })
  }

  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear={true}
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <Row className="mt-3">
        <Col md="12">
          <div id="accordion" className="accordion-wrapper mb-3">
            <Card>
              <CardHeader id="headingOne">
                <Button
                  block
                  outline
                  color=""
                  className="text-left m-0 p-0"
                  onClick={() => toggleAccordion(0)}
                  aria-expanded={state.current === 0}
                  aria-controls="collapseOne"
                >
                  <h5 className="m-0 p-0">Routing Plans</h5>
                </Button>
              </CardHeader>
              <Collapse
                isOpen={state.current === 0}
                data-parent="#accordion"
                id="collapseOne"
                aria-labelledby="headingOne"
              >
                <CardBody>
                  {routingPlans && routingPlans.length ? (
                    routingPlans
                      .sort((el1, el2) =>
                        (el1.name || '').localeCompare(el2.name),
                      )
                      .map(routingPlan => {
                        return (
                          <RoutingPlan
                            key={routingPlan.id}
                            routingPlan={routingPlan}
                          />
                        )
                      })
                  ) : (
                    <div>No routing plan found for this lane</div>
                  )}
                </CardBody>
              </Collapse>
            </Card>
          </div>
        </Col>
      </Row>
    </CSSTransitionGroup>
  )
}
