import { useState, useEffect } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import AlgorithmResource from '../../../resources/algorithm'
import { TimeOfDayRoutingPlanResource } from '../../../resources/routingPlan'
import { useResource, useFetcher } from 'rest-hooks'

const Sites = ({ data: sites }) => {
  let { brandId } = useParams()
  const { results: algorithms } = useResource(AlgorithmResource.list(), {
    customerid: brandId,
  })
  let [routingPlans, setRoutingPlans] = useState({})
  let [routingPlansCache] = useState({})
  const getRoutingPlan = useFetcher(TimeOfDayRoutingPlanResource.detail())
  const getRoutingPlanById = async id => getRoutingPlan({ id })

  useEffect(() => {
    sites.forEach(site => {
      site.endpoints.forEach(endpoint => {
        if (endpoint.linkedRoutingPlans?.length) {
          endpoint.linkedRoutingPlans.forEach(async id => {
            if (!routingPlansCache[id]) {
              routingPlansCache = { ...routingPlansCache, [id]: true }
              const rp = await getRoutingPlanById(id)
              routingPlansCache = { ...routingPlansCache, [id]: rp }
              setRoutingPlans(routingPlansCache)
            }
          })
        }
      })
    })
  }, [])

  const getAlgoById = id => algorithms.find(a => a.id === id)
  const items =
    sites &&
    sites.map(site => (
      <Col key={site.id} md="12">
        <Card className="main-card mb-3">
          <CardHeader>{site.name}</CardHeader>
          <ListGroup flush>
            {site.endpoints.map(endpoint => (
              <ListGroupItem key={endpoint.id}>
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left mr-3 d-none d-sm-block">
                      <i className="pe-7s-call icon-gradient bg-malibu-beach">
                        {' '}
                      </i>
                    </div>
                    <div className="widget-content-left">
                      <div className="widget-heading">{endpoint.name}</div>
                      <div className="widget-subheading">
                        {endpoint.terminationNumber} ({endpoint.id})
                      </div>
                    </div>
                    <div
                      className="d-none d-md-block text-align-end mr-4 text-right flex-grow-1"
                      style={{ flexBasis: '250px' }}
                    >
                      <div
                        className="widget-heading"
                        style={{ fontSize: '0.8rem', fontWeight: '500' }}
                      >
                        {!endpoint.linkedRoutingPlans?.length ? (
                          'No Linked Routing Plans'
                        ) : (
                          <UncontrolledButtonDropdown>
                            <DropdownToggle
                              caret
                              outline
                              className="m-0 p-0"
                              color="link"
                            >
                              Linked Routing Plans:{' '}
                              {endpoint.linkedRoutingPlans?.length}
                            </DropdownToggle>
                            <DropdownMenu>
                              {endpoint.linkedRoutingPlans.map(id => (
                                <DropdownItem key={id}>
                                  {!routingPlans[id] ? (
                                    'loading...'
                                  ) : (
                                    <Link
                                      to={`services/${routingPlans[id].serviceId}/lanes/${routingPlans[id].laneId}/#rp-${id}`}
                                      style={{
                                        color: '#000',
                                        textDecoration: 'none',
                                      }}
                                    >
                                      {routingPlans[id]?.name || id}
                                    </Link>
                                  )}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        )}
                        {' | '}
                        {!endpoint.linkedAlgorithms?.length ? (
                          'No Linked Algorithms'
                        ) : (
                          <UncontrolledButtonDropdown>
                            <DropdownToggle
                              caret
                              outline
                              className="m-0 p-0"
                              color="link"
                            >
                              Linked Algorithms:{' '}
                              {endpoint.linkedAlgorithms?.length}
                            </DropdownToggle>
                            <DropdownMenu>
                              {endpoint.linkedAlgorithms.map(id => (
                                <DropdownItem key={id}>
                                  <Link
                                    to={'algorithms/' + id}
                                    style={{
                                      color: '#000',
                                      textDecoration: 'none',
                                    }}
                                  >
                                    {getAlgoById(id)?.name || id}
                                  </Link>
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        )}
                      </div>
                      <div className="widget-subheading">
                        last updated:{' '}
                        {endpoint.lastUpdated
                          ? new Date(endpoint.lastUpdated).toLocaleString()
                          : 'never'}
                      </div>
                      <div className="widget-subheading"></div>
                    </div>
                    <div
                      style={{ flexBasis: '150px' }}
                      className="text-right text-md-center flex-grow-1 flex-md-grow-0"
                    >
                      {endpoint.isActiveInCruiseControl ? (
                        <>
                          <div className="badge badge-success mr-2 d-none d-sm-inline-block">
                            CRUISE CONTROL ON
                          </div>
                          <div className="badge badge-success mr-2 d-inline-block d-sm-none">
                            CC ON
                          </div>
                          {endpoint.isHealthy ? (
                            <div className="badge badge-success">HEALTHY</div>
                          ) : (
                            <div className="badge badge-danger">UNHEALTHY</div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="badge badge-secondary mr-2 d-none d-sm-inline-block">
                            CRUISE CONTROL OFF
                          </div>
                          <div className="badge badge-secondary mr-2 d-inline-block d-sm-none">
                            CC OFF
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
          <CardFooter className="d-block text-left">
            <Link to={`sites/${site.id}`}>
              <Button outline size="lg" color="info" className="mr-2">
                Edit
              </Button>
            </Link>
          </CardFooter>
        </Card>
      </Col>
    ))
  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear={true}
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <Container fluid>
        <Row className="mt-3">
          {items}
          <Col md="12">
            <Link to={`sites/new`}>
              <Button
                className="btn-icon"
                size="lg"
                color="primary"
                name="addSite"
              >
                Add Site
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </CSSTransitionGroup>
  )
}

export default Sites
